.screen-item {
        @extend .list-item;

    &__name {
        @extend .list-item__name;
    }
    &__playing {
        flex-basis: 20%;
        &__title {
            font-size: 12px;
            color: $gray-dark-secondary;
        }
        &__screen {
            
        }
    }
    &__status {
        flex-basis: 20%;
        text-align: right;
    }
    &__options {
        @extend .list-item__options;
    }
}

.screen_paircode {
    text-align: center;
    padding: 1em;
    &__title{
        font-size: 15px;
    }
    &__code{
        color: $primary-color;
        font-size: 80px;
        font-weight: 600;
    }
}