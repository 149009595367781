.card {
    background-color: $gray-dark;
    border-radius: 0;
    box-shadow: $box-shadow;
}

.badge {
    padding: .6em 1.5em;
    font-size: .5em;
    border-radius: 50px;
    &-secondary {
        background-color: $secondary-color!important;
        color: #d0d0d0;
    }
    &-success {
        background-color: $success-color!important;
        color: #d0d0d0;
    }
    &-pill {
        padding-right: 1.25em;
        padding-left: 1.25em;
    }
}

.nav-pills .nav-link {
    border-radius: 0;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color: transparent;
    border-bottom: 1px solid $primary-color;
}


.nav-tabs {
    border-bottom: 1px solid $hr-color;

    // &:hover .nav-link:hover {
    //     border-bottom: 1px solid $primary-color !important;
    // }

    .nav-link {
        background-color: transparent !important;
        border: 0;
        padding: 20px;

        &.active {
            color: $primary-color;
            border-bottom: 1px solid $primary-color !important;
        }

    }
    
}

.css-tlfecz-indicatorContainer svg {
    fill: #424242;
}
