@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

$box-shadow: 0 1px 15px rgba(0,0,0,.1), 0 1px 8px rgba(0,0,0,.1);

// Box sizes
$nav-height: 75px;
$main-menu: 120px;
$main-menu-small: $main-menu / 2;
$right-panel: 300px;
$margin-panel: 40px;

::selection {
    background: $primary-color;
}

body {
    background-color: $black-light;
    color: $gray-light;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 200;
    min-height: 100vh;
}

strong {
    font-weight: 700;
}

.pointer {
    cursor: pointer;
}

.box-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
}

a {
    text-decoration: none;
    color: $gray-light;
    transition: color .2s;

    &:hover {
        color: $primary-color;
        text-decoration: none;
    }

}

img, video {
    max-width: 100%;
    height: auto;
}

.no-outline {
    outline: 0 !important;
    -webkit-appearance: none;
    box-shadow: none !important;
}

.ellipsize {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.w100 {
    width: 100%;
}

.same-height {
    display: flex;
    flex-wrap: wrap;

    &--full {
        > * {
           width: 100%; 
        }
    }

    > [class*='col-'] {
        display: flex;
        flex-direction: column;
    }
    
}

.flex-1 {
    flex: 1;
}
